<template>
  <div>
    <HighAnalitycsDashboard v-if="stateType === 'High'" />
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/sidebars/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import HighAnalitycsDashboard from '@/components/analytics/highAnalytics/HighAnalitycsDashboard.vue'
import { client } from '../domainConfig'

export default {
  name: 'Analytics',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    HighAnalitycsDashboard,
  },
  data() {
    return {
      client: client.clientName,
      stateType: client.stateType,
    }
  },
}
</script>
